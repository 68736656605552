<div class="authentication-wrapper authentication-3" *ngIf="show; else show2">
    <div class="authentication-inner">
        <!-- Side container -->
        <!-- Do not display the container on extra small, small and medium screens -->
        <div class="d-none d-lg-flex col-lg-8 align-items-center ui-bg-cover ui-bg-overlay-container p-5" [style.backgroundImage]="'url('+ imagen +')'" style="background-size: cover;">
            <div class="dejarVer">
            </div>
            <div class="ui-bg-overlay bg-dark opacity-50"></div>
            <!-- Text -->
            <div class="w-100 text-white px-5 container-text">
                <h1 class="bienvenidoTitulo">BIENVENIDO A<br>ATLAS CONMIGO</h1>
                <a class="bienvenidoCuerpo">
            Un portal diseñado para que nuestros clientes y socios de negocios hagan uso de nuestros servicios en línea.
          </a>
            </div>

            <!-- /.Text -->
        </div>
        <!-- / Side container -->
        <!-- Form container -->
        <div class="d-flex col-lg-4 align-items-center bg-white p-4" *ngIf="show; else show2">
            <!-- Inner container -->
            <!-- Have to add `.d-flex` to control width via `.col-*` classes -->
            <div class="d-flex col-sm-7 col-md-5 col-lg-12 px-0 px-xl-4 mx-auto">
                <div class="w-100">

                    <!-- Logo -->
                    <div class="d-flex justify-content-center align-items-center">
                        <div class="ui-w-60">
                            <div class="w-100 position-relative">
                                <img src="assets/img/icono-atlas.png" height="60px">
                            </div>
                        </div>
                    </div>
                    <!-- / Logo -->
                    <h5 class="slogan">Atlas Conmigo</h5>
                    <!-- Form -->
                    <div *ngIf="Ventana">
                        <form class="my-4">
                            <div class="row mx-0 w-100 align-items-center justify-content-between">
                                <div class="col-7 px-0">
                                    <mat-form-field>
                                        <input type="text" matInput placeholder="Usuario" (ngModelChange)="modificarUsuario()" name="Usuario" [(ngModel)]="credentials.Usuario" required autocomplete="off">
                                        <mat-error>El campo es requerido</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-4 mb-2 px-0">
                                    <button class="btn-block" mat-raised-button color="warn" (click)="abrirModal()" [disabled]="!credentials.Usuario || loading">
                                        <span *ngIf="!loading">Aceptar</span>
                                        <span *ngIf="loading"><i class="fas fa-sync fa-spin"></i> Cargando...</span>
                                    </button>
                                </div>
                            </div>
                            <!-- <div>
                                <mat-form-field class="w-100">
                                    <input type="text" matInput placeholder="Usuario" (ngModelChange)="modificarUsuario()" name="Usuario" [(ngModel)]="credentials.Usuario" required autocomplete="off">
                                    <mat-error>El campo es requerido</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="d-flex justify-content-between align-items-center m-0 my-4">
                                <mat-checkbox name="RememberMe" [(ngModel)]="credentials.RememberMe">
                                    <small>¿Recordar cuenta?</small>
                                </mat-checkbox>
                                <button mat-raised-button color="warn" (click)="abrirModal()" [disabled]="!credentials.Usuario || loading">
                                    <span *ngIf="!loading">Aceptar</span>
                                    <span *ngIf="loading"><i class="fas fa-sync fa-spin"></i> Cargando...</span>
                                </button>
                            </div> -->
                        </form>
                        <!-- / Form -->
                        <div class="text-center my-3">
                            <a class="text-muted">¿No tienes cuenta aún? </a>

                            <!--  <a class="registrate" href="https://hyperion.segurosatlas.com.mx/Portales-beta/Pages/General/Registro.aspx">Registrate</a> -->
                        </div>
                        <div class="text-center my-3">
                            <button mat-stroked-button color="warn" (click)="Display()">Registrate</button>

                        </div>
                        <div class="text-center my-3">
                            <a class="text-muted">¿Deseas pertenecer a nuestra plantilla de proveedores? </a>
                        </div>
                        <div class="text-center my-3">
                            <button mat-stroked-button color="warn" (click)="irRegistroProspectoProveedor()">Participar</button>
                        </div>

                        <div class="text-center my-3">
                            <a class="text-muted">Actualización de datos CSF para facturación</a>
                        </div>
                        <div class="text-center my-3">
                            <button mat-stroked-button color="warn" (click)="irActualizarFacturacion()">Actualizar</button>
                        </div>
                        <div class="text-center my-3">
                            <a class="text-muted">Consulta si tu RFC ya cuenta con los datos actualizados para el CFDI versión 4.0</a>
                        </div>
                        <div class="text-center my-3">
                            <button mat-stroked-button color="warn" (click)="irAConsultarDatosActualizados()">Consultar</button>
                        </div>
                    </div>
                   

                    <div class="w-100 justify-items-center text-center fadeIn animated my-5 margentop " *ngIf="!Ventana">

                        <button class="m-2" mat-raised-button color="primary" (click)="verAsegurado('A')">¿Eres Asegurado?</button>
                        <!-- routerLink="/registroAsegurado" -->
                        <button class="m-2" mat-raised-button color="primary" (click)="verAsegurado('Ag')">¿Eres Agente?</button>
                        <!-- routerLink="/registroAgente" -->
                        <button class="m-2" mat-raised-button color="primary" (click)="verAsegurado('Proveedor')">¿Eres Proveedor?</button>

                        <button class="m-2" mat-raised-button color="primary" (click)="verAsegurado('Promotor')">¿Eres Promotor?</button>

                        <button class="m-2" mat-raised-button color="primary" (click)="verAsegurado('Reasegurador')">¿Eres Reasegurador?</button>
                        <!-- Proveedor -->
                        <button class="m-2" mat-raised-button color="primary" (click)="regresar()">Regresar</button>
                    </div>


                </div>

            </div>
        </div>
        <!-- / Form container -->

    </div>

</div>
<ng-template #show2>


    <ng-container *ngIf="Asegurado">
        <app-registro-asegurado-component (evento)="regresar()"> </app-registro-asegurado-component>
    </ng-container>
    <ng-container *ngIf="Agente">
        <app-registro-agente-component (evento)="regresar()"></app-registro-agente-component>
    </ng-container>
    <ng-container *ngIf="Proveeedor">
        <app-registro-proveedor (evento)="regresar()"></app-registro-proveedor>
    </ng-container>
    <ng-container *ngIf="Promotor">
        <app-registro-promotor (evento)="regresar()"></app-registro-promotor>
    </ng-container>
    <ng-container *ngIf="Reasegurador">
        <app-registro-reasegurador (evento)="regresar()"></app-registro-reasegurador>
    </ng-container>

</ng-template>