import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snack-bar-message',
  template: `
    <div>
      <strong>{{ data.title }}</strong> <!-- Mostrar el título -->
      <div [innerHTML]="data.message"></div> <!-- Mostrar el mensaje con HTML -->
    </div>
  `,
  styleUrls: ['./snack-bar-message.component.scss']
})
export class SnackBarMessageComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: { title: string, message: string }) { }
}
